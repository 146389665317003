import React, { useState, useContext } from "react"
import { useIntl, FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { AppContext } from '../providers/AppContext'
import './contact.css'

const ContactPageInternal = () => {
  const intl = useIntl()
  return (
    <>
      <SEO 
        title={intl.formatMessage({id: 'contact.seo.title'})} 
        keywords={[
          `nvotes`, `secure`, `online`, `voting`, `tools`, `software`, 
          `system`, `election`, `poll`
        ]}
      />
      <article className="slim">
        <h1>
          <FormattedMessage id="contact.seo.title" />
        </h1>

        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeSiulQK33Uw3dXEveAh98WZTtZe5OOJn_w_IoVdUsVq0UHWw/viewform?embedded=true" width="640" height="1289" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </article>
    </>
  )
}

function ContactPage ()
{
  return (
    <Layout>
      <ContactPageInternal />
    </Layout>
  )
}

export default ContactPage
